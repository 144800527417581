import { isEmptyCustom } from '../../../helpers/utils';

/**
 * Transforms the ads.txt data into an IAB compliant format for publisher consumption.
 * Current format: `adExchange`, `accountId`, `relationshipType`, `certificationTag`
 *
 * @param {any[]} adsTxt
 * @returns string
 */
export const adsTxtTransformer = adsTxt =>
    adsTxt
        .map(item => {
            const tagId = !isEmptyCustom(item.tagId) ? `,${item.tagId}` : '';
            return `${item.exchangeDomain},${item.sellerAccountId},${item.accountType}${tagId}`;
        })
        .join('\n');
