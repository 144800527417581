// Components
import Card from 'gumdrops/Card';
import CardBlock from 'gumdrops/CardBlock';
import Column from 'gumdrops/Column';
import LayoutContainer from 'gumdrops/LayoutContainer';
import Row from 'gumdrops/Row';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import LoadingOverlay from '../../../components/common/LoadingOverlay';
// Common
import { adsTxtTransformer } from './adsTxt.utils';
import { Button, ButtonGroup } from 'gumdrops';
import { copyToClipboard } from '../../../helpers/copyToClipboard';
import { sendNotification } from '../../../components/notifications/notificationsReducer';
// Hooks
import { useGetAdsTxtQuery } from './adsTxtApiSlice';
import { useAppSelector } from '../../../store';
import { useDispatch } from 'react-redux';

const adTextCodeStyle = {
    background: '#272822',
    color: '#f8f8f2',
    borderRadius: 2,
    fontFamily: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    fontSize: '0.8rem',
    padding: '1em',
    margin: '.5em 0',
    overflow: 'auto',
};

const AdsTxt = () => {
    const { t } = useTranslation();
    const publisher = useAppSelector(state => state.publisher);
    const {
        data: adsTxts = [],
        error,
        isLoading,
        isError,
    } = useGetAdsTxtQuery({
        publisherId: publisher.id,
        businessUnitIds: publisher.businessUnitId,
    });

    // eslint-disable-next-line no-console
    if (isError) console.log(error);

    const adsTxtsDisplay = adsTxtTransformer(adsTxts);

    const dispatch = useDispatch();

    const handleClick = () => {
        // Copy text to clipboard
        copyToClipboard(adsTxtsDisplay);

        // Dispatch notification
        dispatch(
            sendNotification({
                text: t('accountSettings.adsTxt.copy'),
                context: 'success',
                msToClose: 3000,
            }),
        );
    };

    const [activeTab, setActiveTab] = useState('Ads.txt');

    return (
        <LayoutContainer>
            <Row>
                <Column>
                    <h1 className="gds-text--header-lg -m-b-3" data-cy="ads-txt-title">
                        {t('accountSettings.adsTxt.title')}
                    </h1>
                </Column>
            </Row>
            {isLoading ? (
                <LoadingOverlay showOverlay showDots loadingDotsClass="-m-t-3" />
            ) : isError ? (
                <div className="gds-well gds-well--danger -m-b-2">
                    <p className="gds-well__text">{t('errorMessages.general')}</p>
                </div>
            ) : (
                <Card>
                    <CardBlock className="-p-a-3">
                        <p className="-m-b-2">{t('accountSettings.adsTxt.msg1')}</p>
                        <p>{t('accountSettings.adsTxt.msg2')}</p>

                        <div className="gds-flex gds-flex--justify-between gds-flex__item gds-flex__item--grow-0">
                            <div className="gds-flex">
                                <ButtonGroup className="-m-r-3" size="xs">
                                    <Button
                                        group
                                        className={`${activeTab === 'Ads.txt' ? 'gds-button--active' : ''}`}
                                        onClick={() => setActiveTab('Ads.txt')}
                                        context="default">
                                        Ads.txt
                                    </Button>
                                    <Button
                                        group
                                        className={`${activeTab === 'App-Ads.txt' ? 'gds-button--active' : ''}`}
                                        onClick={() => setActiveTab('App-Ads.txt')}
                                        context="default">
                                        App-Ads.txt
                                    </Button>
                                </ButtonGroup>
                            </div>
                            <div
                                className="gds-tooltip gds-tooltip--top"
                                data-tooltip="Copy Clipboard">
                                <Button size="xs" context="primary" onClick={handleClick}>
                                    <i className="fas fa-copy" />
                                </Button>
                            </div>
                        </div>
                        {/* TODO: App-ads.txt content is subject to change once app_ads_txt_reseller_entries table is created */}
                        <div className="ads-txt-content">
                            {activeTab === 'Ads.txt' ? (
                                <pre className="language-html -m-v-0" style={adTextCodeStyle}>
                                    <code style={{ whiteSpace: 'pre-wrap' }}>{adsTxtsDisplay}</code>
                                </pre>
                            ) : (
                                <pre className="language-html -m-v-0" style={adTextCodeStyle}>
                                    <code style={{ whiteSpace: 'pre-wrap' }}>{adsTxtsDisplay}</code>
                                </pre>
                            )}
                        </div>

                        <p
                            className="-m-t-3"
                            dangerouslySetInnerHTML={{
                                __html: t('accountSettings.adsTxt.msg3'),
                            }}
                        />
                    </CardBlock>
                </Card>
            )}
        </LayoutContainer>
    );
};

export default AdsTxt;
